<template>
  <v-container
    class="mb-6"
    style="max-width: 1000px"
  >
    <div class="mb-5">
      アイテム編集
    </div>
    <v-row>
      <v-col>
        <v-card :class="['outset', 'welfare-card', 'px-10', 'py-10']">
          <v-form
            ref="form"
            v-model="valid"
            @submit.prevent="submit"
          >
            <v-text-field
              v-model="name"
              label="アイテム名"
              :counter="300"
              :rules="[required]"
            />
            <v-textarea
              v-model="description"
              label="説明"
              :counter="1000"
              rows="4"
            />
            <v-textarea
              v-model="precaution"
              label="注意事項 (省略可)"
              rows="4"
            />
            <v-text-field
              v-model="price"
              label="ポイント数"
              type="number"
              :rules="[required]"
            />
            <v-select
              v-model="category"
              :items="categories"
              label="アイテムカテゴリー"
              item-text="name"
              item-value="val"
              :rules="[required]"
            />
            <v-text-field
              v-model="expirationPeriod"
              label="有効期間(日)"
              type="number"
            />

            <div class="img-upsert-div">
              メイン画像
              <v-img
                v-if="mainImgPreviewUrl"
                :src="mainImgPreviewUrl"
                contain
                height="250"
                width="250"
              />
              <v-img
                v-else-if="!mainImgDelFlg && item.mainImgSrc"
                :src="item.mainImgSrc"
                contain
                height="250"
                width="250"
              />
              <v-img
                v-else
                src="@/assets/img/no_image.png"
                contain
                height="250"
                width="250"
              />
              <div>
                <v-file-input
                  hide-input
                  prepend-icon="mdi-camera-plus"
                  accept="image/*"
                  @change="onChangeImg($event, 'main')"
                />
                <v-btn
                  rounded
                  outlined
                  color="error"
                  @click="removeImg('main')"
                >
                  画像を削除
                </v-btn>
              </div>
            </div>
            <div class="img-upsert-div">
              サブ画像1
              <v-img
                v-if="subImg1PreviewUrl"
                :src="subImg1PreviewUrl"
                contain
                height="250"
                width="250"
              />
              <v-img
                v-else-if="!subImg1DelFlg && item.subImg1Src"
                :src="item.subImg1Src"
                contain
                height="250"
                width="250"
              />
              <v-img
                v-else
                src="@/assets/img/no_image.png"
                contain
                height="250"
                width="250"
              />
              <div>
                <v-file-input
                  hide-input
                  prepend-icon="mdi-camera-plus"
                  accept="image/*"
                  @change="onChangeImg($event, 'sub1')"
                />
                <v-btn
                  rounded
                  outlined
                  color="error"
                  @click="removeImg('sub1')"
                >
                  画像を削除
                </v-btn>
              </div>
            </div>
            <div class="img-upsert-div">
              サブ画像2
              <v-img
                v-if="subImg2PreviewUrl"
                :src="subImg2PreviewUrl"
                contain
                height="250"
                width="250"
              />
              <v-img
                v-else-if="!subImg2DelFlg && item.subImg2Src"
                :src="item.subImg2Src"
                contain
                height="250"
                width="250"
              />
              <v-img
                v-else
                src="@/assets/img/no_image.png"
                contain
                height="250"
                width="250"
              />
              <div>
                <v-file-input
                  hide-input
                  prepend-icon="mdi-camera-plus"
                  accept="image/*"
                  @change="onChangeImg($event, 'sub2')"
                />
                <v-btn
                  rounded
                  outlined
                  color="error"
                  @click="removeImg('sub2')"
                >
                  画像を削除
                </v-btn>
              </div>
            </div>
            <div class="img-upsert-div">
              サブ画像3
              <v-img
                v-if="subImg3PreviewUrl"
                :src="subImg3PreviewUrl"
                contain
                height="250"
                width="250"
              />
              <v-img
                v-else-if="!subImg3DelFlg && item.subImg3Src"
                :src="item.subImg3Src"
                contain
                height="250"
                width="250"
              />
              <v-img
                v-else
                src="@/assets/img/no_image.png"
                contain
                height="250"
                width="250"
              />
              <div>
                <v-file-input
                  hide-input
                  prepend-icon="mdi-camera-plus"
                  accept="image/*"
                  @change="onChangeImg($event, 'sub3')"
                />
                <v-btn
                  rounded
                  outlined
                  color="error"
                  @click="removeImg('sub3')"
                >
                  画像を削除
                </v-btn>
              </div>
            </div>
            <div class="img-upsert-div">
              サブ画像4
              <v-img
                v-if="subImg4PreviewUrl"
                :src="subImg4PreviewUrl"
                contain
                height="250"
                width="250"
              />
              <v-img
                v-else-if="!subImg4DelFlg && item.subImg4Src"
                :src="item.subImg4Src"
                contain
                height="250"
                width="250"
              />
              <v-img
                v-else
                src="@/assets/img/no_image.png"
                contain
                height="250"
                width="250"
              />
              <div>
                <v-file-input
                  hide-input
                  prepend-icon="mdi-camera-plus"
                  accept="image/*"
                  @change="onChangeImg($event, 'sub4')"
                />
                <v-btn
                  rounded
                  outlined
                  color="error"
                  @click="removeImg('sub4')"
                >
                  画像を削除
                </v-btn>
              </div>
            </div>
            <v-row justify="center">
              <v-btn
                rounded
                type="submit"
                color="info"
                class="small-outset mt-7"
              >
                更新する
              </v-btn>
              <v-btn
                rounded
                outlined
                class="small-outset mt-7 ml-5"
                @click="$router.push({ name: 'ManageItemDetail', params: { uuid: item.uuid }}, () => {})"
              >
                戻る
              </v-btn>
            </v-row>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: () => {}
    },
    categories: {
      type: Array,
      default: () => []
    }
  },

  data: () => ({
    valid: false,
    name: '',
    description: '',
    precaution: '',
    price: 0,
    category: 0,
    expirationPeriod: 0,
    mainImgPreviewUrl: '',
    subImg1PreviewUrl: '',
    subImg2PreviewUrl: '',
    subImg3PreviewUrl: '',
    subImg4PreviewUrl: '',
    mainImg: '',
    subImg1: '',
    subImg2: '',
    subImg3: '',
    subImg4: '',
    mainImgDelFlg: false,
    subImg1DelFlg: false,
    subImg2DelFlg: false,
    subImg3DelFlg: false,
    subImg4DelFlg: false,
    required: value => !!value || 'この項目は入力が必須です'
  }),

  mounted () {
    this.name = this.item.name
    this.description = this.item.description
    this.precaution = this.item.precaution
    this.price = this.item.price
    this.category = this.item.category
    this.expirationPeriod = this.item.expirationPeriod
  },

  methods: {
    submit () {
      this.$emit('submit', this)
    },
    onChangeImg (file, imgType) {
      if (!file) {
        return
      }
      switch (imgType) {
        case 'main':
          this.mainImg = file
          this.mainImgPreviewUrl = URL.createObjectURL(file)
          this.mainImgDelFlg = this.item.mainImgSrc !== null
          break
        case 'sub1':
          this.subImg1 = file
          this.subImg1PreviewUrl = URL.createObjectURL(file)
          this.subImg1DelFlg = this.item.subImg1Src !== null
          break
        case 'sub2':
          this.subImg2 = file
          this.subImg2PreviewUrl = URL.createObjectURL(file)
          this.subImg2DelFlg = this.item.subImg2Src !== null
          break
        case 'sub3':
          this.subImg3 = file
          this.subImg3PreviewUrl = URL.createObjectURL(file)
          this.subImg3DelFlg = this.item.subImg3Src !== null
          break
        case 'sub4':
          this.subImg4 = file
          this.subImg4PreviewUrl = URL.createObjectURL(file)
          this.subImg4DelFlg = this.item.subImg4Src !== null
          break
      }
    },
    removeImg (imgType) {
      switch (imgType) {
        case 'main':
          this.mainImg = ''
          this.mainImgPreviewUrl = ''
          this.mainImgDelFlg = true
          break
        case 'sub1':
          this.subImg1 = ''
          this.subImg1PreviewUrl = ''
          this.subImg1DelFlg = true
          break
        case 'sub2':
          this.subImg2 = ''
          this.subImg2PreviewUrl = ''
          this.subImg2DelFlg = true
          break
        case 'sub3':
          this.subImg3 = ''
          this.subImg3PreviewUrl = ''
          this.subImg3DelFlg = true
          break
        case 'sub4':
          this.subImg4 = ''
          this.subImg4PreviewUrl = ''
          this.subImg4DelFlg = true
          break
      }
    }
  }
}
</script>
