import store from '@/store'
import Presenter from '@/components/pages/manage/item/edit/Presenter'

export default {
  data: () => ({
    item: {}
  }),

  async created () {
    this.item = await store.dispatch('item/getDetail', this.$route.params.uuid)
  },

  render (h) {
    if (this.item.name) {
      return h(Presenter, {
        props: {
          categories: [
            { name: '会食', val: 100 },
            { name: '食品', val: 200 },
            { name: '雑貨', val: 300 },
            { name: '健康・美容', val: 400 },
            { name: 'レジャー', val: 500 }
          ],
          item: this.item
        },

        on: {
          async submit (presenter) {
            if (!presenter.$refs.form.validate()) {
              return
            }
            presenter.$store.commit('updateLoading', { flag: true })
            const formData = new FormData()
            formData.append('name', presenter.name)
            formData.append('description', presenter.description)
            if (presenter.precaution) {
              formData.append('precaution', presenter.precaution)
            }
            formData.append('price', presenter.price)
            formData.append('category', presenter.category)
            formData.append('expirationPeriod', presenter.expirationPeriod)
            formData.append('main_img', presenter.mainImg)
            formData.append('sub_img1', presenter.subImg1)
            formData.append('sub_img2', presenter.subImg2)
            formData.append('sub_img3', presenter.subImg3)
            formData.append('sub_img4', presenter.subImg4)
            formData.append('main_img_del_flg', presenter.mainImgDelFlg)
            formData.append('sub_img1_del_flg', presenter.subImg1DelFlg)
            formData.append('sub_img2_del_flg', presenter.subImg2DelFlg)
            formData.append('sub_img3_del_flg', presenter.subImg3DelFlg)
            formData.append('sub_img4_del_flg', presenter.subImg4DelFlg)
            const itemUuid = presenter.$route.params.uuid
            const payload = {
              'uuid': itemUuid,
              'formData': formData
            }
            await presenter.$store.dispatch('item/update', payload)
            store.commit('message/show', {
              message: '更新しました。',
              color: 'info',
              timeout: 4000
            })
            presenter.$router.push({ name: 'ManageItemDetail', params: { uuid: itemUuid }})
          }
        }
      })
    }
  }
}
